import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={`https://${ process.env.GATSBY_AUTH0_DOMAIN }/api/v2/`}
    scope="profile email users read:current_user"
    connection={process.env.GATSBY_AUTH0_CONNECTION}
  >
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_SITE_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  </Auth0Provider>
)
